// 👇 : the scrollbar of the textarea is the default one because
// the width of the resizer is the same as the width of the scrollbar.
// If the scrollbar is reduced (as here), the resizer is not usable.
*:not(.qima-input-textarea__input) {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px $qima-color-neutral-cloud;
    border-radius: $qima-spacing-m;
  }

  &::-webkit-scrollbar {
    width: $qima-scrollbar-width;
    height: $qima-spacing-m;
  }

  &::-webkit-scrollbar-thumb {
    @include qima-quick-transition(background-color);

    border-radius: $qima-spacing-m;
    background-color: $qima-color-neutral-pearl;

    &:hover {
      cursor: pointer;
      background-color: $qima-color-neutral-smoke;
    }

    &:focus {
      background-color: $qima-color-neutral-smoke;
    }
  }

  &::-webkit-scrollbar:horizontal {
    height: $qima-scrollbar-width;
  }
}
