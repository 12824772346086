.qp-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: $white;
  border: 1px solid $pearl;
  box-sizing: border-box;
}
