// Provide those for outside user
// Don't use them internally

/* Below are two of forty example classes after build
```
  .qima-mt-min {
    margin-top: $qima-spacing-min;
  }
  .qima-m-min {
    margin: $qima-spacing-min;
  }
```
 */
@include qima-spacing-generator(margin, m);
