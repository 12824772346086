@keyframes qimaAnimationShrink {
  0% {
    height: 100%;
    width: 100%;
  }

  30% {
    height: 50%;
    width: 50%;
  }

  60%,
  100% {
    height: 100%;
    width: 100%;
  }
}

