@import 'node_modules/@qima/ngx-qima/styles/qima-imports';

/**
 * 🚨 @IMPORTANT 🚨
 * Colors defined here are also defined in an enum inside colors.models.ts
 * Each colour change has to be applied to both files
 * @todo This warning will have to be removed at the end of the colors migration
 * (no more usage of those variables)
 * 👆 👆 👆 👆 👆 👆
 */

// Primary UI colors

$midnight: $qima-color-neutral-carbon;
$midnight-hover: $qima-color-neutral-seal;
$midnight-opacity: rgb($midnight, 96.1%);
$ocean: $qima-decorative-elderberry;
$oceanopacity: rgb($ocean, 20.8%);
$pool: $qima-color-pool-500;
$pool-hover: $qima-color-pool-600;
$sunset: $qima-decorative-guava;
$sunset-light: $qima-color-orange-200;
$white: $qima-color-neutral-white;
$danger: $qima-color-cherry-600;

// Secondary UI colors
$seal: $qima-color-neutral-seal;
$smoke: $qima-color-neutral-smoke;
$pearl: $qima-color-neutral-pearl;
$breeze: $qima-color-pool-200;
$mist: $qima-color-neutral-mist;
$sky: $qima-color-pool-100;
$cloud: $qima-color-neutral-cloud;

// Color coding / Alerts, errors, defects and inspection results
$cherry: $qima-color-cherry-500;
$cherry-light: $qima-color-cherry-100;
$orange: $qima-color-orange-500;
$orange-hover: $qima-color-orange-600;
$orange-light: $qima-color-orange-100;
$lemon: $qima-color-lemon-500;
$kiwi: $qima-color-kiwi-500;
$kiwi-hover: $qima-color-kiwi-600;
$kiwi-deactivated: $qima-color-kiwi-100;
$kiwi-light: $qima-color-kiwi-100;

// Color coding for each Inspection types
// PSI
$blueberry: $qima-decorative-blueberry;
// CLC
$olive: $qima-decorative-olive;
// IPC
$peach: $qima-decorative-peach;
// PM
$grapes: $qima-decorative-grapes;
// ILI
$mandarin: $qima-decorative-mandarin;
// SP
$plum: $qima-decorative-plum;
// OTHER
$forest: $qima-decorative-forest;
