.qima-autolink {
  @extend %qima-reset-a;
  @include qima-quick-transition(color);

  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $qima-color-pool-500;

  &:focus,
  &:hover {
    text-decoration: underline;
    color: $qima-color-pool-600;
  }
}
