body {
  @extend %qima-scroll;

  color: $qima-color-neutral-carbon;
  line-height: $qima-body-default-line-height;

  &.qima-font-family-noto {
    font-family: 'Noto Sans SC', sans-serif;

    input, button, textarea {
      font-family: 'Noto Sans SC', sans-serif;
    }
  }

  &.qima-font-family-inter {
    font-family: 'Inter', sans-serif;

    input, button, textarea {
      font-family: 'Inter', sans-serif;
    }
  }
}


.qima-global-scroll-block {
  display: block !important;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
