// QUICK FIX for missing bootstrap print mixins
// @todo: Technical task created https://asiainspection.atlassian.net/browse/PL-10929
@use 'sass:map';

$border-width: 1px !default;
$black: #000 !default;
$gray-300: #dee2e6 !default;
$gray-500: #adb5bd !default;
$border-color: $gray-300 !default;
$table-border-color: $border-color !default;
$print-page-size: a3 !default;
$grid-breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
) !default;
$print-body-min-width: map.get($grid-breakpoints, 'lg') !default;

@media print {
  body {
    @include qp-print;
  }

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a {
    &:not(.btn) {
      text-decoration: underline;
    }
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: $border-width solid $gray-500;
    page-break-inside: avoid;
  }

  // https://stackoverflow.com/questions/22310544/disable-table-header-repeating-on-page-break-when-rendering-as-pdf
  thead {
    display: table-row-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap specific changes start
  // Specify a size and min-width to make printing closer across browsers.
  // We don't set margin here because it breaks `size` in Chrome. We also
  // don't use `!important` on `size` as it breaks in Chrome.
  @page {
    size: $print-page-size;
  }

  body {
    min-width: $print-body-min-width !important;
  }

  .container {
    min-width: $print-body-min-width !important;
  }

  // Bootstrap components
  .navbar {
    display: none;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: $white !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid $gray-300 !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: $table-border-color;
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: $table-border-color;
  }

  // Bootstrap specific changes end
}

body.body-print {
  @include qp-print;
}
