@import 'projects/library/shared-scss/qp-imports';

.qp-modal-container {
  @include qp-width(520px);

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: $white;
  padding: $spacing-xxl;
  justify-content: space-evenly;
  @media screen and (max-width: $mobile-max-width) {
    @include qp-width(90vw);

    padding: $spacing-l;
  }
  @media screen and (max-width: $mobile-max-width) {
    .qp-modal-footer {
      @include qp-width(100%);
    }
  }

  .qp-modal-header {
    @include qp-width(100%);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: $mobile-max-width) {
      margin-top: $spacing-xl;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
    }

    .confirm-info {
      @include qp-font(20px);

      font-weight: bold;
      @media screen and (max-width: $mobile-max-width) {
        @include qp-font(14px);
      }
    }

    .confirm-consequence {
      @extend %qp-typography-body-1;

      margin-top: 10px;
      @media screen and (max-width: $mobile-max-width) {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
        margin-bottom: $spacing-xl;
      }
    }
  }

  .qp-modal-content {
    @include qp-width(90%);
  }

  .qp-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: $spacing-s;
    @media screen and (max-width: $mobile-max-width) {
      display: block;
      text-align: center;

      &__spacer {
        @include qp-width(100%);
        @include qp-height(10px);
      }
    }

    &__spacer {
      @include qp-width(26px);

      display: block;
    }
  }
}
