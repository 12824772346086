.qima-h1 {
  @extend %qima-typography-h1;
}

.qima-h2 {
  @extend %qima-typography-h2;
}

.qima-h3 {
  @extend %qima-typography-h3;
}

.qima-h4 {
  @extend %qima-typography-h4;
}

.qima-h5 {
  @extend %qima-typography-h5;
}

.qima-h6 {
  @extend %qima-typography-h6;
}

.qima-body1 {
  @extend %qima-typography-body1;
}

.qima-body2 {
  @extend %qima-typography-body2;
}

.qima-normal {
  font-weight: normal !important;
}

.qima-semi-bold {
  font-weight: 600 !important;
}

.qima-bold {
  font-weight: bold !important;
}
