// Improve the scroll experience
// Always include it on your element that has a scrollbar
%qima-scroll {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  // iOS momentum scrolling fix
  > * {
    -webkit-transform: translateZ(0);
  }
}
