// Provide those for outside user
// Don't use them internally

/* Below are two of forty example classes after build
```
  .qima-pt-min {
    padding-top: $qima-spacing-min;
  }
  .qima-p-min {
    padding: $qima-spacing-min;
  }
```
 */
@include qima-spacing-generator(padding, p);
