@mixin qima-spacing-generator($name, $abbreviation) {
  @each $space, $space_name in
    ($qima-spacing-min, min),
    ($qima-spacing-base, base),
    ($qima-spacing-xs, xs),
    ($qima-spacing-s, s),
    ($qima-spacing-m, m),
    ($qima-spacing-l, l),
    ($qima-spacing-xl, xl),
    ($qima-spacing-xxl, xxl) {
    @each $direction, $direction_name in
      (#{$name}-top, #{$abbreviation}t),
      (#{$name}-right, #{$abbreviation}r),
      (#{$name}-bottom, #{$abbreviation}b),
      (#{$name}-left, #{$abbreviation}l),
      (#{$name}, #{$abbreviation}) {
      .qima-#{$direction_name}-#{$space_name} {
        #{$direction}: qima-pixels-to-em($space) !important;
      }
    }
  }
}
