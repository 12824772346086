@import 'projects/library/shared-scss/qp-imports';

.qp-btn {
  @include qima-quick-transition(color, background-color, border-color, box-shadow);

  display: inline-block;
  font-weight: normal;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  &:hover {
    color: #212529;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  &.disabled,
  &:disabled {
    opacity: 0.65;
  }

  &:not(:disabled, .disabled) {
    cursor: pointer;
  }

  &-sm,
  &-group-sm > & {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  &-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;

    &:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }

    &:focus,
    &.focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
    }

    &.disabled,
    &:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    .show > &.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf;
    }

    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
    }
  }

  &-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;

    &:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
    }

    &:focus,
    &.focus {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
      box-shadow: 0 0 0 0.2rem rgb(72 180 97 / 50%);
    }

    &.disabled,
    &:disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    .show > &.dropdown-toggle {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430;
    }

    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgb(72 180 97 / 50%);
    }
  }

  &-danger {
    color: #fff;
    background-color: $danger;
    border-color: $danger;

    &:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
    }

    &:focus,
    &.focus {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
    }

    &.disabled,
    &:disabled {
      color: #fff;
      background-color: $danger;
      border-color: $danger;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    .show > &.dropdown-toggle {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d;
    }

    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
    }
  }
}
