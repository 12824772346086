// nz-select-dropdown
.measurement__dropdown {
  &.ant-select-dropdown {
    width: 200px !important;
    border-radius: 6px;
    box-shadow: 0 3px 8px 0 rgb($midnight, 15%);

    .ant-select-dropdown-menu-item {
      padding: 13px 18px;
      color: $midnight;
      font-weight: normal;

      &-selected,
      &-active {
        font-weight: normal;
        background-color: $white;
      }

      &:hover {
        font-weight: normal;
        background-color: $sky;
      }

      &-selected {
        position: relative;

        &::after {
          content: '';
          background-image: url('/assets/outline/ic-tick-medium-midnight-100.svg');
          position: absolute;
          right: 8px;
          width: 24px;
          display: block;
          height: 24px;
          top: 12px;
          color: $midnight;
        }
      }
    }
  }
}
