@import '../../styles/qima-imports';

/* stylelint-disable-next-line selector-type-no-unknown */
.qima-overlay-pane cdk-dialog-container {
  @extend %qima-shadow-three;

  position: relative;
  padding: $qima-dialog-padding;
  width: $qima-dialog-content-width;
  border-radius: $qima-dialog-border-radius;
  background: $qima-color-neutral-white;
}
