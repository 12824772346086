@import 'projects/library/shared-scss/qp-styles';

.cdk-global-scrollblock {
  overflow-y: initial;
}

// Useful to change the cursor when dragging with Material CDK Drag and Drop
// @see https://stackoverflow.com/questions/53674488/how-can-i-change-the-cursor-when-dragging-material-cdk-drag-and-drop
body.inheritCursors * {
  cursor: inherit !important;
}

body {
  position: var(--mobileScrollLocked, initial);
  overflow: var(--defectChecklistHiddenOverflow, auto);
  @media screen and (min-width: $breakpoint-tablet) {
    position: initial;
  }
}

.modal-panel {
  &--mobile-bottom {
    @media screen and (max-width: $breakpoint-sm) {
      position: fixed !important;
      bottom: 0;
    }
  }
}
