@keyframes qimaAnimationFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

