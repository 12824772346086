%qima-reset-focus {
  // Sadly for the override property, Safari styles should be reset like this
  outline: none;

  // Remove Chrome and Safari override when focusing
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
