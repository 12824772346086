@import 'projects/library/shared-scss/qp-imports';

.qp-form-control {
  @include qp-placeholder($smoke);
  @include qp-font(16px);
  @include qima-quick-transition(border-color, box-shadow);

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: $midnight;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $smoke;
  border-radius: 0.25rem;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $midnight;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
    color: $seal;
    background-color: $cloud;
    opacity: 1;
  }
}

.qp-form-group {
  margin-bottom: 1rem;
}

.qp-form-text {
  display: block;
  margin-top: 0.25rem;
}
