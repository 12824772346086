@import 'projects/library/shared-scss/qp-imports';

// Apply mobile css rules if we are under $mobile-max-width OR if we are in orientation portrait (IPad width is over $mobile-max-width)
@media screen and (max-width: $mobile-max-width), screen and (orientation: portrait) {
  .qp-button > * {
    &.type-hero-red {
      &:active {
        background-color: $qima-color-cherry-600;
      }

      &:not(:active) {
        background-color: $qima-brand-red;
      }
    }

    &.type-hero-blue {
      &:active {
        background-color: $pool-hover;
      }

      &:not(:active) {
        background-color: $pool;
      }
    }

    &.type-hero-green {
      &:active {
        background-color: $kiwi-hover;
      }

      &:not(:active) {
        background-color: $kiwi;
      }
    }

    &.type-hollow {
      &:active {
        background-color: $midnight;
        color: $white;
      }

      &:not(:active) {
        background-color: $cloud;
        color: $midnight;
      }
    }
  }
}

.qp-button > * {
  @include qima-quick-transition(all);

  border: 0;
  border-radius: 6px;
  height: $qp-button-size-large-height;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline-style: none;
  cursor: pointer;
  text-decoration: none !important;

  &:disabled,
  &[disabled] {
    cursor: no-drop !important;
  }

  &.type-hero-red {
    background-color: $qima-brand-red;
    color: $white;

    &:hover,
    &:active {
      background-color: $qima-color-cherry-600;
    }

    &:disabled,
    &[disabled] {
      background-color: $pearl;
      color: $smoke;
    }
  }

  &.type-hero-pool {
    background-color: $pool;
    color: $white;

    &:hover,
    &:active {
      background-color: $pool-hover;
    }

    &:disabled,
    &[disabled] {
      background-color: $pearl;
      color: $smoke;
    }
  }

  &.type-hero-blue {
    background-color: $pool;
    color: $white !important;

    &:hover,
    &:active {
      background-color: $pool-hover;
    }

    &:disabled,
    &[disabled] {
      background-color: $pearl;
      color: $smoke;
    }
  }

  &.type-hero-green {
    background-color: $kiwi;
    color: $white;

    &:hover,
    &:active {
      background-color: $kiwi-hover;
    }

    &:disabled,
    &[disabled] {
      background-color: $pearl;
      color: $smoke;
    }
  }

  &.type-simple {
    background-color: transparent;
    border: none;
    color: $midnight;

    &:hover,
    &:active {
      color: $pool-hover;
    }

    &[disabled] {
      color: $smoke;

      em {
        color: $smoke;
      }
    }

    em {
      color: $pool-hover;
    }
  }

  &.type-hollow {
    background-color: transparent;
    color: $midnight;
    border: solid 1px $midnight;

    &:hover,
    &:active {
      border-color: $midnight-hover;
      color: $midnight-hover;
    }

    &:disabled,
    &[disabled] {
      background-color: transparent;
      color: $smoke;
      border: solid 1px $smoke;
    }
  }

  &.size-small {
    height: $qp-button-size-small-height;
    padding-left: 12px;
    padding-right: 12px;
    @extend %qp-typography-interactive-2;
  }

  &.size-medium {
    height: $qp-button-size-medium-height;
    padding-left: 16px;
    padding-right: 16px;
    @extend %qp-typography-interactive-1;
  }

  &.size-large {
    padding-left: 16px;
    padding-right: 16px;
    @extend %qp-typography-interactive-1;

    .button-icon {
      margin-right: 6px;
      font-size: 20px;
    }
  }

  &.width-full {
    min-width: 260px;
  }

  &.width-large {
    min-width: 156px;
  }

  &.width-medium {
    min-width: 96px;
  }

  &.width-small {
    min-width: 60px;
  }

  .button-icon {
    margin-right: 6px;
    font-size: 20px;
  }
}
