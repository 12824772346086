@keyframes qimaAnimationRotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


